import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/Seo';

interface IProps {
  data: any;
}

const Page: React.FC<IProps> = ({ data }) => {
  const post = data.markdownRemark;

  return (
    <Layout>
      <SEO title={post.frontmatter.title} keywords={[`gatsby`, `application`, `react`]} />
      <section className="grid-container">
        <div className="grid-x grid-margin-x">
          <div className="cell small-12">
            <h1>{post.frontmatter.title}</h1>
            <div dangerouslySetInnerHTML={{ __html: post.html }} />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
      }
      html
      fields {
        slug
      }
    }
  }
`;

export default Page;
